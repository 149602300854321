import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './index.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { GlobalConfig } from '@/configs/config';
import { routes } from './routes';
import { ApiError } from '@/generated/requests';

dayjs.extend(utc);
dayjs.extend(duration);

Sentry.init({
  dsn: GlobalConfig.SENTRY_DSN,
  beforeSend(event, hint) {
    console.log('hint', hint.originalException);
    if (hint.originalException instanceof Error) {
      const error = hint.originalException;
      if (error.message === 'Login required') {
        return null;
      }
    }
    if (hint.originalException instanceof ApiError) {
      const error = hint.originalException;
      if (error.status === 400 || error.status === 404) {
        return null;
      }
    }
    return event;
  },
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      enableInp: true,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [GlobalConfig.API_BASE_URL],
    }),
  ],
  tracePropagationTargets: [GlobalConfig.APP_BASE_URL],
  release: GlobalConfig.SENTRY_RELEASE,
  environment: GlobalConfig.SENTRY_ENVIRONMENT,
  tracesSampleRate: GlobalConfig.SENTRY_TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: GlobalConfig.SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: GlobalConfig.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
});
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
