type Config = {
  API_BASE_URL: string;
  APP_BASE_URL: string;
  APP_VERSION: string;
  RELEASE_DATE: string;
  APP_ENV: string;
  SENTRY_DSN: string;
  SENTRY_ENVIRONMENT: string;
  SENTRY_RELEASE: string;
  SENTRY_TRACES_SAMPLE_RATE: number;
  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: number;
  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: number;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENTID: string;
  AUTH0_API_AUDIENCE: string;
};

const GlobalConfig: Config = {
  API_BASE_URL: import.meta.env.VITE_API_BASE_URL ?? window.location.origin,
  APP_BASE_URL: import.meta.env.VITE_APP_BASE_URL ?? window.location.origin,
  APP_VERSION: import.meta.env.VITE_APP_VERSION,
  RELEASE_DATE: import.meta.env.VITE_RELEASE_DATE,
  APP_ENV: import.meta.env.VITE_APP_ENV,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  SENTRY_ENVIRONMENT: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  SENTRY_RELEASE: import.meta.env.VITE_SENTRY_RELEASE,
  SENTRY_TRACES_SAMPLE_RATE: Number(
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
  ),
  // prettier-ignore

  SENTRY_REPLAYS_SESSION_SAMPLE_RATE: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  // prettier-ignore

  SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  AUTH0_CLIENTID: import.meta.env.VITE_AUTH0_CLIENTID,
  AUTH0_API_AUDIENCE: import.meta.env.VITE_AUTH0_API_AUDIENCE,
};

export { GlobalConfig };
