import { lazy } from 'react';

// Copied from https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
export const lazyWithRetries: typeof lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 5; i++) {
        // @ts-ignore
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          // @ts-ignore
          setTimeout(resolve, 1000 * 2 ** i);
        });

        // this assumes that the exception will contain this specific text with the url of the module
        // if not, the url will not be able to parse and we'll get an error on that
        // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"
        // @ts-ignore
        let url: URL;
        try {
          url = new URL(
            error.message
              .replace('Failed to fetch dynamically imported module: ', '')
              .trim(),
          );
        } catch {
          throw error;
        }

        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        // @ts-ignore
        url.searchParams.set('t', `${+new Date()}`);

        try {
          // @ts-ignore
          // eslint-disable-next-line no-await-in-loop
          return await import(/* @vite-ignore */ url.href);
        } catch (e) {
          // @ts-ignore
          console.log('retrying import');
        }
      }
      throw error;
    }
  };
  return lazy(retryImport);
};
