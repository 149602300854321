import { redirect, RouteObject } from 'react-router-dom';
import { lazyWithRetries } from '@/configs/lazyWithRetries';

const Login = lazyWithRetries(() => import('@/components/Login'));
const DemonteurRoot = lazyWithRetries(() =>
  import('@/pages/Demonteur/DemonteurRoot').then((module) => ({
    default: module.DemonteurRoot,
  })),
);
const SpecificDemonteur = lazyWithRetries(
  () => import('@/pages/Demonteur/SpecificDemonteur'),
);
const DemonteurLoader = lazyWithRetries(
  () => import('@/pages/Demonteur/DemonteurLoader'),
);
const VehiculeDemonteur = lazyWithRetries(() =>
  import('@/pages/Demonteur/DemonteurRoot').then((module) => ({
    default: module.VehiculeDemonteur,
  })),
);
const Assignation = lazyWithRetries(() =>
  import('@/pages/Demonteur/DemonteurRoot').then((module) => ({
    default: module.Assignation,
  })),
);
const DemonteurList = lazyWithRetries(
  () => import('@/pages/Administrateur/DemonteurList'),
);
const DemonteurForm = lazyWithRetries(
  () => import('@/pages/Administrateur/DemonteurForm'),
);
const Garage = lazyWithRetries(() => import('@/pages/Superviseur/Garage'));
const Site = lazyWithRetries(() => import('@/pages/Superviseur/Site'));
const VehiculeSuperviseur = lazyWithRetries(
  () => import('@/pages/Superviseur/VehiculeSuperviseur'),
);
const GoToPreviousSite = lazyWithRetries(
  () => import('@/pages/Superviseur/GoToPreviousSite'),
);
const SentryRouteErrorFallback = lazyWithRetries(
  () => import('@/pages/SentryRouteErrorFallback'),
);
const App = lazyWithRetries(() => import('@/App'));

export const routes = [
  {
    Component: App,
    ErrorBoundary: SentryRouteErrorFallback,
    children: [
      {
        index: true,
        Component: Login,
      },
      {
        path: 'demonteur',
        Component: DemonteurLoader,
        children: [
          {
            index: true,
            Component: SpecificDemonteur,
          },
          {
            path: ':demonteur',
            Component: DemonteurRoot,
            children: [
              {
                path: 'search',
                Component: Assignation,
              },
              {
                path: 'file-number/:fileNumber',
                Component: VehiculeDemonteur,
              },
            ],
          },
        ],
      },
      {
        path: 'superviseur',
        children: [
          {
            index: true,
            Component: GoToPreviousSite,
          },
          {
            path: 'magasin/:siteCode',
            children: [
              {
                index: true,
                Component: Site,
              },
              {
                path: 'garage/:garageCode',
                children: [
                  {
                    index: true,
                    Component: Garage,
                  },
                  {
                    path: 'demonteur/:demonteur/file-number/:fileNumber',
                    Component: VehiculeSuperviseur,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            index: true,
            loader: () => redirect('demonteurs'),
          },
          {
            path: 'demonteurs',
            Component: DemonteurList,
            children: [
              { path: 'form/:demonteurId?', Component: DemonteurForm },
            ],
          },
        ],
      },
    ],
  },
] satisfies RouteObject[];
